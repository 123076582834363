import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        
        <h1>
          NOVA LABS
        </h1>

        <a className="Contact-us-link" href="mailto: contact@novalabs.dev">Contact Us</a>
        
      </header>
    </div>
  );
}

export default App;
